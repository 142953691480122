import { useState, useEffect, useContext } from 'react';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../helpers/auth-config';
import { callMsGraph, getPhoto, getGroups } from '../helpers/ms-graph-api';
import UserContext from '../context/user-context';
import { Layout, Avatar } from 'antd';
import { dateFormats, formatDateTime } from '../shared/utils';
import { P21UserProvider } from '../context/p21-user-context';
import { HeaderMenu } from './header-menu';
import {
  defaultWindowHeight,
  Notification,
  useNotificationContext,
} from './shared/notification';
import 'antd/dist/antd.css';

const { Header, Content, Footer } = Layout;

const SiteLayout = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const { windowHeight } = useNotificationContext();

  const account = useAccount(accounts[0] || {});
  const { dispatch } = useContext(UserContext);

  const [graphData, setGraphData] = useState(null);
  const [profilePic, setProfilePic] = useState('');

  const [graphDataLoaded, setGraphDataLoaded] = useState(false);

  useEffect(() => {
    if (
      !graphDataLoaded &&
      account &&
      inProgress !== InteractionStatus.Startup
    ) {
      requestProfileData();
    }
  }, [account, inProgress]);

  const requestProfileData = async () => {
    setGraphDataLoaded(true);
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });

      const {
        preferred_username: userEmail,
        name,
        oid: objectId,
      } = response?.idTokenClaims || {};

      const graphResponse = await callMsGraph(response.accessToken);
      setGraphData(graphResponse);

      const groupResponse = await getGroups(response.accessToken);
      dispatch({
        type: 'SET_GROUPS',
        payload: {
          groups: groupResponse?.value || [],
          user: {
            emailAddress: userEmail?.toLowerCase(),
            name: name,
            objectId: objectId,
          },
        },
      });

      const photoResponse = await getPhoto(response.accessToken);
      const blob = await photoResponse.blob();
      const imageUrl = URL.createObjectURL(blob);
      setProfilePic(imageUrl);

      if (userEmail) {
        localStorage.setItem('userEmail', `${userEmail}`.toLowerCase());
        localStorage.setItem('username', `${name}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const ErrorComponent = ({ error }) => {
    return <div>An Error Occurred: {error.errorCode}</div>;
  };

  const Loading = () => {
    return <div>Authentication in progress...</div>;
  };

  const authRequest = {
    ...loginRequest,
  };

  const ProfilePicture = graphData ? (
    <div
      style={{ float: 'right' }}
      title={`${graphData.displayName}\n${graphData?.userPrincipalName}`}
    >
      <Avatar src={profilePic} style={{ fontWeight: 'bold' }} size={45}>
        {graphData.displayName
          ?.split(/\s/)
          ?.map((m) => m[0]?.toUpperCase())
          .join('') || 'PAM'}
      </Avatar>
    </div>
  ) : null;

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <P21UserProvider graphData={graphData}>
        <Notification>
          <Layout className='layout'>
            <Header style={{ padding: '0 16px' }}>
              <HeaderMenu profilePic={profilePic} />
              {ProfilePicture}
            </Header>
          </Layout>
          <Content
            style={{
              padding: '20px',
              minHeight: (windowHeight || defaultWindowHeight) - 140,
              background: '#fff',
            }}
          >
            {children}
          </Content>
          <Footer
            style={{
              textAlign: 'center',
              padding: '4px',
              fontSize: '12px',
              backgroundColor: 'transparent',
            }}
          >
            Container and Packaging ©
            {formatDateTime(new Date(), dateFormats.year)}
            {process.env.NEXT_PUBLIC_BUILD_ID
              ? ` - Build [${process.env.NEXT_PUBLIC_BUILD_ID}]`
              : ''}
          </Footer>
        </Notification>
      </P21UserProvider>
    </MsalAuthenticationTemplate>
  );
};

export default SiteLayout;
