import { styled } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardContent,
  Stack,
  Typography,
  TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { createTheme } from '@mui/material/styles';
import { Box } from '@mui/system';

export const colors = {
  highlightBlue: '#1965e0',
  highlightLightOrange: '#fff9e8',
  highlightLightCyan: '#e8fff4',
  highlightLightGray: '#f2f2f2',
  highlightGreen: '#23c423',
  highlightLightGreen: '#ebfceb',
  highlightDarkCyan: '#008B8B',
  warningLow: '#ffdb59',
  warningMedium: '#ff8e3d',
  warningHigh: '#f31111',
  caption: '#627388',
  rowHighlight: '#ffdb59 !important',
  rowAlternativeBackground: '#f5f5f5',
  white: '#FFFFFF',
  lightGray: '#f0f0f0',
  lightGray2: '#DFDFDF',
  lightGray3: '#fafafa',
  darkGray: 'darkgray',
  purple: '#b5709a',
};

export const stringToColor = (str) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color + '30';
};

export const GridContainer = ({
  children,
  noLeftMargin,
  noTopMargin,
  ...props
}) => {
  props = {
    ...props,
    sx: {
      ...props.sx,
      marginLeft: noLeftMargin ? '-8px !important' : props.sx?.marginLeft,
      marginTop: noTopMargin ? '0px !important' : props.sx?.marginTop,
    },
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-end'
      spacing={1}
      rowSpacing={1}
      {...props}
    >
      {children}
    </Grid>
  );
};

export const GridItem = ({ children, ...props }) => {
  return (
    <Grid item {...props}>
      {children}
    </Grid>
  );
};

export const GridDivider = ({ xs = 12 }) => (
  <Grid item xs={xs} sx={{ paddingTop: '0px !important' }} />
);

export const PaddedCard = ({ children, sx, padding = 1, ...props }) => (
  <Card sx={{ height: '100%', padding: padding, ...sx }} {...props}>
    {children}
  </Card>
);

export const CardContainer = ({ children, backgroundColor, ...props }) => {
  return (
    <Card variant={'outlined'} {...props.cardProps}>
      <CardContent
        sx={{
          padding: 1,
          '&:last-child': {
            paddingBottom: 1,
          },
          ...(backgroundColor && { backgroundColor: backgroundColor }),
        }}
        {...props.cardContentProps}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export const PaddedTableCell = ({
  children,
  verticalAlign = 'center',
  ...props
}) => {
  return (
    <TableCell
      style={{ padding: '8px 0 8px 8px', verticalAlign: verticalAlign }}
      {...props}
    >
      {children}
    </TableCell>
  );
};

export const PaddedTableCellNoBottomBorder = styled(PaddedTableCell)({
  borderBottom: 0,
});

export const LabeledText = ({
  label,
  text,
  showEmptyValue,
  sx,
  align,
  title,
} = {}) => {
  if (!text && !showEmptyValue) {
    return <></>;
  }

  return (
    <Stack title={title}>
      <Typography variant='caption' align={align}>
        {label}
      </Typography>
      {(typeof text === 'string' && (
        <Typography variant='body2' align={align} sx={sx}>
          {text || '-'}
        </Typography>
      )) ||
        text}
    </Stack>
  );
};

export const SmallText = ({ text, variant = 'caption', ...props }) => {
  if (!text || !`${text}`.trim()) {
    return <></>;
  }

  return (
    <Typography variant={variant} {...props}>
      {text}
    </Typography>
  );
};

const defaultFontSize = 15;

export const defaultTheme = createTheme({
  components: { MuiCard: { defaultProps: { variant: 'outlined' } } },
  typography: {
    fontSize: defaultFontSize,
    display4: {
      fontSize: defaultFontSize,
    },
    display3: {
      fontSize: defaultFontSize,
    },
    display2: {
      fontSize: defaultFontSize,
    },
    display1: {
      fontSize: defaultFontSize,
    },
    headline: {
      fontSize: defaultFontSize,
    },
    title: {
      fontSize: defaultFontSize,
    },
    subheading: {
      fontSize: defaultFontSize,
    },
    body2: {
      fontSize: defaultFontSize - 1,
    },
    body1: {
      fontSize: defaultFontSize - 1,
    },
    caption: {
      fontSize: defaultFontSize - 4,
      color: colors.caption,
    },
    button: {
      fontSize: defaultFontSize,
      textTransform: 'initial',
    },
  },
});

export const CompactTableCell = styled(TableCell)(() => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colors.lightGray,
      color: colors.caption,
      fontSize: 12,
      padding: '0 10px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '3px 10px',
    },
  };
});

export const CompactTableRow = styled(TableRow)(() => {
  return {
    '&:nth-of-type(even)': {
      backgroundColor: colors.rowAlternativeBackground,
    },
  };
});

export const TableTitle = ({ title }) => (
  <Box
    sx={{
      backgroundColor: colors.lightGray2,
      width: '100%',
      padding: '5px 10px',
    }}
  >
    <Typography align='left' sx={{ fontWeight: 'bold' }}>
      {title}
    </Typography>
  </Box>
);

export const HighlightedText = ({ backgroundColor, text, bold }) => (
  <Typography
    sx={{
      fontWeight: bold ? 'bold' : 'normal',
      borderRadius: '4px',
      padding: '0 3px',
      color: 'white',
      textAlign: 'center',
      backgroundColor: backgroundColor,
    }}
  >
    {text}
  </Typography>
);

export const sizes = {
  small: { xl: 2, md: 4, sm: 6, xs: 12 },
  medium: { xl: 3, md: 6, xs: 12 },
  large: { xl: 4, md: 6, xs: 12 },
  larger: { xl: 6, xs: 12 },
};
